import { CSSObject } from '@emotion/react'

import { MediaQueries } from '../ScreenSize'
import { color, font, fontSize, radius, space } from '../tokens'
import { Theme } from '../themeTypes'

const defaultButtonColors = {
  backgroundColor: color('white'),
  borderColor: color('gray-700'),
  color: color('gray-300'),
}

const DefaultTheme: Theme = {
  name: 'default',

  textPresets: {
    Base: {
      fontFamily: font('default'),
      fontSize: fontSize(2),
      fontWeight: 500,
    } as CSSObject,
    Compact: {
      fontFamily: font('default'),
      fontSize: fontSize(1),
      fontWeight: 500,
    } as CSSObject,
    Monospace: {
      fontFamily: font('monospace'),
      fontSize: fontSize(2),
      letterSpacing: '0.03em',
    } as CSSObject,
  },

  buttonColors: {
    default: {
      outline: {
        ...defaultButtonColors,
        '&:hover': {
          color: color('gray-100'),
          backgroundColor: color('gray-900'),
        },
      },
      filled: {
        color: color('white'),
        backgroundColor: color('gray-100'),
        borderColor: color('gray-100'),
      },
    },
    primary: {
      outline: {
        ...defaultButtonColors,
        '&:hover': {
          color: color('primary-400'),
          borderColor: color('primary-400'),
          backgroundColor: color('primary-100'),
        },
      },
      filled: {
        color: color('white'),
        backgroundColor: color('primary-400'),
        borderColor: color('primary-400'),
      },
    },
    secondary: {
      outline: {
        ...defaultButtonColors,
        '&:hover': {
          color: color('accent-500'),
          borderColor: color('accent-400'),
          backgroundColor: color('accent-100'),
        },
      },
      filled: {
        color: color('gray-100'),
        backgroundColor: color('accent-400'),
        borderColor: color('accent-400'),
      },
    },
    alert: {
      outline: {
        ...defaultButtonColors,
        '&:hover': {
          backgroundColor: color('alert-400'),
          borderColor: color('gray-700'),
          color: color('gray-100'),
        },
      },
      filled: {
        backgroundColor: color('alert-400'),
        borderColor: color('gray-700'),
        color: color('gray-100'),
        '&:hover': {
          borderColor: color('gray-300'),
        },
      },
    },
    danger: {
      outline: {
        ...defaultButtonColors,
        '&:hover': {
          color: color('danger-400'),
          borderColor: color('danger-400'),
          backgroundColor: color('danger-100'),
        },
      },
      filled: {
        color: color('white'),
        backgroundColor: color('danger-400'),
        borderColor: color('danger-400'),
      },
    },
  },
  styles: {
    Card: {
      borderRadius: radius(2),
      boxShadow: `0 0 ${space(2)} -${space(1)} ${color('gray-300', 0.266)}`,
      '&[data-selected="true"]': {
        backgroundColor: color('primary-100'),
        borderColor: color('primary-400'),
        boxShadow: `0 0 ${space(2)} -${space(1)} ${color('primary-400')}`,
      },
    },
    CardGrid: {
      gap: space(1),
    },
    CardFooter: {
      padding: space(5),
      // paddingTop: space(3),
      // paddingBottom: space(3),
      borderBottomLeftRadius: radius(2),
      borderBottomRightRadius: radius(2),
      'div[data-selected="true"] &': {
        backgroundColor: color('primary-100'),
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: color('primary-100'),
      },
    },
    Link: {
      color: 'inherit',
      '&:focus': {
        outline: 'none',
        textDecoration: 'underline',
      },
      '&[aria-disabled="true"], &[aria-disabled="true"]:active,  &[aria-disabled="true"]:visited': {
        pointerEvents: 'none',
      },
    },
    Panel: {
      marginBottom: space(4),
    },
    // applied to the label component of PopupMenu
    PopupMenuToggle: {
      color: color('gray-300'),
      '&:hover': {
        cursor: 'pointer',
        color: color('gray-000'),
      },
    },
    ModalContent: {
      position: 'relative',
      borderRadius: radius(2),
      paddingTop: space(5),
      paddingLeft: space(5),
      paddingRight: space(5),
      maxHeight: `calc(100vh - 3 * ${space(5)})`,
      width: '480px',
      overflowY: 'auto',
      backgroundColor: color('white'),
      ':focus': {
        outline: 0,
      },
      [MediaQueries.Small]: {
        WebkitOverflowScrolling: 'touch',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
        paddingLeft: space(3),
        paddingRight: space(3),
        maxHeight: `100%`,
        maxWidth: `100%`,
        borderRadius: 0,
        '& > div': {
          display: 'block',
          width: '100%',
          paddingTop: space(5),
          paddingBottom: space(5),
        },
      },
    },
    ModalOverlay: {
      position: 'fixed',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: 'rgba(0,0,0,0.65)',
      zIndex: 1000,
      opacity: 0,
      transition: 'opacity 150ms ease-in-out',
      '&.ReactModal__Overlay--after-open': {
        opacity: 1,
      },
      '&.ReactModal__Overlay--before-close': {
        opacity: 0,
      },
      [MediaQueries.Small]: {
        backgroundColor: color('white'),
      },
    },
  },
}

export default DefaultTheme
